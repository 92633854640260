@import '../../style/themes/index';
@import '../../style/mixins/index';

@breadcrumb-prefix-cls: ~'@{ant-prefix}-breadcrumb';

.@{breadcrumb-prefix-cls} {
  .reset-component;

  color: @breadcrumb-base-color;
  font-size: @breadcrumb-font-size;

  .@{iconfont-css-prefix} {
    font-size: @breadcrumb-icon-font-size;
  }

  a {
    color: @breadcrumb-link-color;
    transition: color 0.3s;
    &:hover {
      color: @breadcrumb-link-color-hover;
    }
  }

  & > span:last-child {
    color: @breadcrumb-last-item-color;
    a {
      color: @breadcrumb-last-item-color;
    }
  }

  & > span:last-child &-separator {
    display: none;
  }

  &-separator {
    margin: @breadcrumb-separator-margin;
    color: @breadcrumb-separator-color;
  }

  &-link {
    > .@{iconfont-css-prefix} + span,
    > .@{iconfont-css-prefix} + a {
      margin-left: 4px;
    }
  }

  &-overlay-link {
    > .@{iconfont-css-prefix} {
      margin-left: 4px;
    }
  }
}

@import './rtl';

@font-size-base: 16px;@font-color-base: #fff;@dark-half: #494949;@shadow-1: 4px 4px 20px 0 rgba(0,0,0,0.01);@transition-ease-in: all 0.3s ease-out;@transition-ease-out: all 0.3s ease-out;@theme-sidebar-bgcolor-red: #d80b0b;@theme-sidebar-bgcolor-black: #000;@theme-sidebar-bgcolor-yellow: #ff9800;@theme-sidebar-bgcolor-blue: #1e90ff;@menu-collapsed-width: 45px;@primary-color: #2B63F8;@info-color: #42ACC9;@success-color: #1AAB95;@processing-color: #1890ff;@error-color: #F74236;@highlight-color: #f5222d;@warning-color: #C28757;@normal-color: #d9d9d9;@menu-dark-submenu-bg: #DEE4FF;@menu-bg: #DEE4FF;@menu-popup-bg: #DEE4FF;@menu-dark-highlight-color: #000000;@menu-highlight-color: #2B63F8;@menu-dark-bg: #2B63F8;